$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-buyer-layout/exports/scss/_layout';

.container {
    @include primary-viewport-width;
    background-color: $sassy-color-moonstone;
    padding: $sassy-spacing-large 0 $sassy-spacing-small;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &.useWhiteBg {
        background-color: $sassy-color-white;
        padding: 0 $sassy-spacing-x-large $sassy-spacing-large;

        .viewMoreButton {
            background-color: $sassy-color-white;
        }
        @include sassy-breakpoint-below(mobile) {
            padding: $sassy-spacing-small;
        }
    }
    @include sassy-breakpoint-below(mobile) {
        padding: $sassy-spacing-small;
    }
}
.sectionHeader {
    @include sassy-font-header;
    margin: 0 0 $sassy-spacing-x-large;
    @include sassy-breakpoint-below(mobile) {
        @include sassy-font-header($size: medium);
        margin: 0 0 $sassy-spacing-medium;
    }
}
.articlesWrapper {
    @include sassy-breakpoint-above(desktop) {
        margin: 0 $sassy-spacing-large $sassy-spacing-small;
        display: grid;
        grid-gap: $sassy-spacing-xx-large;
        max-width: 1240px;

        // grid layout variants
        &.col1 {
            max-width: 700px;
            grid-template-columns: 1fr;
        }
        &.col2 {
            grid-template-columns: repeat(2, 1fr);
        }
        &.col3 {
            max-width: 915px;
            grid-template-columns: repeat(3, 1fr);
        }
        &.col4 {
            grid-template-columns: repeat(4, 1fr);
        }
    }
}

.viewMoreButton {
    display: block;
    margin: $sassy-spacing-medium auto;
    width: 200px;
    background-color: $sassy-color-moonstone;
    @include sassy-breakpoint-below(mobile) {
        width: 100%;
        margin: 0;
    }
}
