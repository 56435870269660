$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_spacing';

.container {
    display: flex;
}

.radioInputs {
    &:not(:first-child) {
        padding-left: $sassy-spacing-large;
    }
}
