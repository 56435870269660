$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_breakpoints';

.wrapper {
    @include sassy-font-body($size: x-small, $type: none);
    padding: $sassy-spacing-x-small 0;
    list-style: none;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
        display: none;
    }
    @include sassy-breakpoint-below(tablet-portrait) {
        padding: 0;
        margin: $sassy-spacing-x-small 0;
    }
}

.lastItem {
    color: $sassy-color-noir;
}

.lastItem,
.item {
    display: inline-block;
    & + &:before {
        // text decorations are not propagated on inline-block - underline on slashes not drawn
        // this is needed because css content !== text
        display: inline-block;
        cursor: initial;
        content: '/';
        padding: 0 $sassy-spacing-x-small;
        @include sassy-breakpoint-below(tablet-portrait) {
            padding: 0 6px;
        }
    }
}
