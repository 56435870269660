$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_zindex';

.tileWrapper {
    position: relative;
    display: flex;
    min-width: 0;
    flex-direction: column;
    align-items: center;

    @include sassy-breakpoint-above(desktop) {
        margin-bottom: $sassy-spacing-small;
    }
}

.tileWidth {
    flex-basis: calc(100% / 2);
    max-width: calc(100% / 2); // IE11 flexbox/border-box issue

    @include sassy-breakpoint-above(desktop) {
        flex-basis: calc(100% / 3);
        max-width: calc(100% / 3); // IE11 flexbox/border-box issue
    }
}

.completeInfo {
    .favoritesWrapper {
        @include sassy-z-index(bump);
        position: absolute;
        top: 0;
        right: 0;
        margin-top: 0;
        padding: $sassy-spacing-x-small;
    }
}

.reducedIcon {
    transform: scale(0.8);
}

.heartIconWrapperSpacing {
    margin: 0 auto;
}

.favoritesWrapper {
    @include sassy-z-index(bump);
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 0;
    padding: $sassy-spacing-small;

    @include sassy-breakpoint-below(mobile) {
        padding: $sassy-spacing-x-small;
    }
}

.favoritesItemProvider {
    width: 100%;
}
