$user-type: 'buyer';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';

$device-type: 'responsive';

.container {
    display: flex;

    @if $device-type == 'responsive' {
        padding: 1px 0 $sassy-spacing-small 0; // 1px to account for border
    } @else {
        padding: $sassy-spacing-small 0;
    }
}

.divider {
    align-self: center;

    @if $device-type == 'responsive' {
        @include sassy-font-body($size: small, $type: none);
        margin: 0 $sassy-spacing-x-small;
    } @else {
        margin: 0 $sassy-spacing-small;
    }
}

.error {
    @include sassy-font-body($size: x-small, $type: none);
    color: $sassy-color-alert-primary;
    margin-bottom: 15px;
}

.arrow {
    width: 16px;
    height: 100%;
}

.inputRangeButton {
    padding: 0 $sassy-spacing-x-small !important; // FINDING-13982: prevents being overwritten by lazily loaded css from modal
    margin-left: $sassy-spacing-x-small;
    border-width: 1px !important; // FINDING-13982: prevents being overwritten by lazily loaded css from modal
    flex-shrink: 0;
}
