$user-type: 'buyer';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_colors';

$device-type: 'responsive';

.container {
    margin: $sassy-spacing-medium 0;
}
.result {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @if $device-type == 'responsive' {
        @include sassy-font-body($size: small, $type: none);
        cursor: pointer;
    }
    color: $sassy-color-iron;
}

.listItem {
    &:not(:last-child) {
        padding-bottom: $sassy-spacing-medium;
    }
}

.searchResultIcon {
    height: 25px;
    width: 25px;
    margin-right: $sassy-spacing-small;
    vertical-align: middle;
    color: $sassy-color-storm;
}
