$user-type: 'buyer';
@import '~dibs-sassy/exports/_buyerLinks';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';

$device-type: 'responsive';

// increase specificity in order to override Link component styles from NotificationQueue
button.container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    // uses Link, which may be a button, which inherits text-align: center;
    text-align: left;

    &:last-of-type {
        padding-bottom: $sassy-spacing-small;
    }

    // override default stylings on all a tags
    a {
        @include sassy-buyer-link-no-decoration();
    }

    @include sassy-font-body($size: small, $type: none);

    &:hover {
        color: initial;
    }

    @if $device-type == 'responsive' {
        padding-top: $sassy-spacing-x-small;
        &:hover {
            cursor: pointer;
        }

        &:first-of-type {
            padding-top: 0;
        }
    } @else {
        padding-top: $sassy-spacing-small;
        @include sassy-buyer-standard-link();
        &:first-of-type {
            padding: 0 0 $sassy-spacing-small;
        }
    }
}

.disabled {
    pointer-events: none;
    color: $sassy-color-iron;
}

.indent {
    margin-left: $sassy-spacing-small;
    padding-right: $sassy-spacing-small;
    width: calc(100% - #{$sassy-spacing-small});
}

.text {
    &:hover {
        color: $sassy-color-noir;
        text-decoration: underline;
    }
}

.selected {
    .text {
        @include sassy-font-body(small, heavy);
        &:hover {
            color: initial;
        }
    }
}

.count {
    @include sassy-font-body($size: x-small, $type: none);
    margin-top: 3px;
    padding-left: $sassy-spacing-x-small;
    color: $sassy-color-satan;
}
