$user-type: 'buyer';
@import '~dibs-sassy/exports/_spacing';

$device-type: 'responsive';

.wrapper {
    display: block;

    @if $device-type == 'responsive' {
        margin-bottom: $sassy-spacing-x-small;
    }
}
