$user-type: 'buyer';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';

$device-type: 'responsive';

.wrapper {
    display: block;

    @if $device-type == 'responsive' {
        margin-bottom: $sassy-spacing-x-small;
    }
}

.title {
    @include sassy-font-sub-header;
    flex-basis: 100%;
    color: $sassy-color-noir;
}
