$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';

$tile-width: 48px;

.unitSelectorContainer {
    display: flex;
    margin-bottom: $sassy-spacing-medium;
}

.unitSelector {
    margin-right: $sassy-spacing-x-small;
}

.unitSelectorLabel {
    @include sassy-font-body($size: small);
    margin-left: $sassy-spacing-x-small;
    margin-right: $sassy-spacing-small;
}

.expandFilters {
    @include sassy-font-body($size: small);
    padding-top: $sassy-spacing-x-small;
    padding-bottom: $sassy-spacing-small;
}

.ringSizeFilterContainer {
    display: grid;
    grid-template-columns: repeat(4, $tile-width);
    grid-template-rows: 30px;
    grid-auto-rows: 30px;
    gap: $sassy-spacing-x-small $sassy-spacing-x-small;

    @include sassy-breakpoint-below(tablet-portrait) {
        grid-template-columns: repeat(5, $tile-width);
    }
}

.spacer {
    height: 50px;
}

.ringSizeFilterTile {
    display: flex;
    border: 1px solid $sassy-color-dolphin;
    width: $tile-width;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.selected {
        border: 2px solid $sassy-color-noir;
    }
}
