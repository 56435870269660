$user-type: 'buyer';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';

$device-type: 'responsive';

.container {
    &:first-of-type {
        padding-top: $sassy-spacing-small;
    }
}

.title {
    @include sassy-font-sub-header($type: normal);

    @if $device-type == 'responsive' {
        padding-bottom: $sassy-spacing-small;
    }
}
