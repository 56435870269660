$user-type: 'buyer';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_buyerLinks';

$device-type: 'responsive';

.container {
    display: flex;
    $row-padding: $sassy-spacing-small;
    @if $device-type == 'responsive' {
        $row-padding: $sassy-spacing-x-small;
    }
    &:not(:first-of-type) {
        padding-top: $row-padding;
    }
    &:last-of-type {
        padding-bottom: $sassy-spacing-small;
    }
}

.inputWrapper {
    width: 100%;
}

.anchor {
    text-decoration: none;
    @if $device-type == 'responsive' {
        @include sassy-font-body($size: small, $type: none);
    }
}

.label {
    @include sassy-buyer-standard-link($underline: none);
    width: 100%;
    min-width: 0;
    &:hover {
        text-decoration: underline;
    }

    @if $device-type == 'responsive' {
        @include sassy-font-body($size: small, $type: none);
    }
}

.disabled {
    pointer-events: none;
}

.count {
    color: $sassy-color-satan;
    margin-top: 3px;
    margin-left: $sassy-spacing-x-small;

    @if $device-type == 'responsive' {
        @include sassy-font-body($size: x-small, $type: none);
    } @else {
        @include sassy-font-body($size: small, $type: none);
    }
}
