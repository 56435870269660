$user-type: 'buyer';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';

$device-type: 'responsive';

.container {
    color: $sassy-color-iron;
    overflow: auto;
}

.inputWrapper {
    background: $sassy-color-alabaster;
    @include sassy-font-body($size: small, $type: none);
    margin-bottom: $sassy-spacing-small;
}

.button {
    display: block;
    padding-bottom: $sassy-spacing-small;
}

.searchInput {
    margin: $sassy-spacing-small 0;
    background: $sassy-color-white;
    color: $sassy-color-noir;

    @if $device-type == 'responsive' {
        @include sassy-font-body($size: small, $type: none);
    }
}
