$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_hidden';
@import '~dibs-sassy/exports/_focus';
@import '~dibs-sassy/exports/_buyerLinks';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';

.container {
    display: flex;
    align-items: center;
    padding-top: $sassy-spacing-small;

    &:first-of-type {
        padding-top: 0;
    }

    &:last-of-type {
        padding-bottom: $sassy-spacing-small;
    }
}

.checkbox {
    @include sassy-visually-hidden;
    &:global(.focus-visible) + .label {
        outline: none;
        @include sassy-focus-ring;
    }
    &:focus-visible + .label {
        @include sassy-focus-ring;
    }
}

.stoneCutContainer {
    height: 25px;
    width: 25px;
    color: $sassy-color-storm;
    margin-right: $sassy-spacing-small;
}

.label {
    cursor: pointer;
    @include sassy-font-body(small);
    width: 100%;
}

.count {
    @include sassy-font-body($size: x-small, $type: none);
    margin-left: $sassy-spacing-x-small;
    color: $sassy-color-satan;
}

.input {
    @include sassy-buyer-link-no-decoration();
    color: $sassy-color-noir;
    width: 100%;
    min-width: 0;
    pointer-events: none;
    display: flex;
    align-items: center;
}

.checked {
    color: $sassy-color-noir;
}
