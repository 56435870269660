$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_animations';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_buyerLinks';
@import '~dibs-sassy/exports/_zindex';

$tile-height: 400px;
$image-height: 240px;
$title-height: 15px;
$price-width: 45px;

.wrapper {
    height: $tile-height;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    margin-top: $sassy-spacing-x-small;
    padding: 0 $sassy-spacing-small ($sassy-spacing-large + $sassy-spacing-x-small)
        $sassy-spacing-small;

    flex-basis: calc(100% / 2);
    max-width: calc(100% / 2); // IE11 flexbox/border-box issue

    @include sassy-breakpoint-above(desktop) {
        @include sassy-animate-shadow-hover;
        margin-bottom: $sassy-spacing-small;

        &:hover {
            .tileHovered {
                display: block;
            }
        }
        flex-basis: calc(100% / 3);
        max-width: calc(100% / 3); // IE11 flexbox/border-box issue
    }
    &.item5InRow {
        max-width: calc(100% / 5);
        flex-basis: calc(100% / 5);
    }
    &.item2InRow {
        max-width: calc(100% / 2);
        flex-basis: calc(100% / 2);
    }

    @include sassy-breakpoint-below(tablet-portrait) {
        padding: $sassy-spacing-larger $sassy-spacing-x-small $sassy-spacing-small;
        margin-top: 0;
        &.photosOnly {
            padding-top: 0;
        }
    }
}

.image,
.title,
.subtitle,
.price {
    @include sassy-animate-loading-shimmer;
}

.tile {
    height: 100%;
    width: 100%;
    padding: $sassy-spacing-small;
}

.image {
    height: $image-height;
    margin-bottom: $sassy-spacing-small;
}

.title,
.subtitle {
    height: $title-height;
    margin-bottom: $sassy-spacing-small;
}

.price {
    height: $title-height;
    width: $price-width;
}
