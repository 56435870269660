$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_zindex';

.noScroll {
    overflow: hidden;
    position: fixed !important; // Override inline body styles
}
.body {
    @include sassy-z-index(overlay);
    background: $sassy-color-white;
    bottom: 0;
    left: 0;
    position: fixed;
    overflow: scroll;
    right: 0;
    top: 0;
}
.aboveOverlay {
    @include sassy-z-index(above-overlay);
}
.menu {
    composes: body;
    border-top: 5px solid $sassy-color-buyer-primary;
}
.includePadding {
    padding: 5px 2.5%;
}
.transparent {
    background-color: $sassy-color-overlay-background;
}
