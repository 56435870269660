$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_webkitLineClamp';

.article {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @include sassy-breakpoint-below(tablet-landscape) {
        margin: 0 0 $sassy-spacing-small;
    }

    &.stacked {
        @include sassy-breakpoint-above(desktop) {
            display: block;

            .articleElement {
                &:first-child {
                    flex: unset;
                    margin: 0 0 $sassy-spacing-medium;
                }
                &:last-child {
                    flex: unset;
                    margin: 0;
                }

                .header {
                    @include webkitLineClamp($lineClamp: 2);
                }

                .body {
                    @include webkitLineClamp($lineClamp: 3);
                }
            }
        }
    }

    &.hidden {
        display: none;
    }
}
.articleElement {
    flex: 1;
    position: relative;
    &:first-child {
        min-height: 141px;
        margin-right: $sassy-spacing-small;

        @include sassy-breakpoint-below(tablet-landscape) {
            min-height: auto;
            max-height: 110px;
            margin-right: 0;
        }
    }
    &:last-child {
        margin-left: $sassy-spacing-small;
    }
}
.featuredImg {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.header {
    @include sassy-font-header($size: medium);
    margin: 0 0 $sassy-spacing-x-small;
    text-overflow: ellipsis;
    word-break: break-word;

    @include webkitLineClamp($lineClamp: 3);

    @include sassy-breakpoint-below(tablet-landscape) {
        @include sassy-font-header($size: small);
    }
}

.body {
    @include sassy-font-body;
    margin: 0;
    text-overflow: ellipsis;
    word-break: break-word;

    @include webkitLineClamp($lineClamp: 4);

    @include sassy-breakpoint-below(tablet-landscape) {
        @include sassy-font-body($size: x-small);
        @include webkitLineClamp($lineClamp: 2);
    }
}
