$user-type: 'buyer';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_spacing';

$device-type: 'responsive';

.container {
    display: flex;
    flex-wrap: wrap;

    @if $device-type == 'responsive' {
        padding: 0 0 $sassy-spacing-medium 0;
    }
}

.colorSelect {
    flex-basis: calc(100% / 7);
    padding-bottom: 14%;
    position: relative;
}

.swatch {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 100%;
    border: 1px solid $sassy-color-dolphin;
    appearance: none; // unstyle checkbox
    pointer-events: auto;
    outline-offset: 1px;

    @if $device-type == 'responsive' {
        margin: 3px;
        height: $sassy-spacing-medium;
        width: $sassy-spacing-medium;
        cursor: pointer;
    } @else {
        height: calc(100% - #{$sassy-spacing-x-small * 2});
        width: calc(100% - #{$sassy-spacing-x-small * 2});
        margin: $sassy-spacing-x-small;
    }

    &.selected {
        box-shadow: 0 0 0 1px $sassy-color-white, 0 0 0 3px $sassy-color-iron;
    }
}

.disabled {
    pointer-events: none;
}
