$user-type: 'buyer';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';

$device-type: 'responsive';

.container {
    margin: $sassy-spacing-small 0;
    @if $device-type == 'responsive' {
        margin-top: $sassy-spacing-x-small;
        @include sassy-font-body($size: small, $type: none);
    } @else {
        @include sassy-font-body($type: none);
    }
}

.clear {
    &:before {
        content: '|';
        margin: 0 $sassy-spacing-x-small;
    }
}
