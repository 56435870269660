$user-type: 'buyer';
$device-type: 'responsive';
.container {
    position: relative;
}

.arrow {
    width: 15px;
    height: 15px;
}
