$user-type: 'buyer';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';

$device-type: 'responsive';

// increase specificity in order to override Link component styles from NotificationQueue
button.button {
    display: block;
    // split padding into clickable area and non-clickable area with margin
    padding-bottom: $sassy-spacing-x-small;
    margin-bottom: $sassy-spacing-x-small;

    @if $device-type == 'responsive' {
        @include sassy-font-body($size: small, $type: none);
    } @else {
        @include sassy-font-body($type: none);
    }
}

// increase specificity in order to override Link component styles from NotificationQueue
button.buttonSpacingTop {
    padding-top: $sassy-spacing-x-small;
    @if $device-type != 'responsive' {
        // split padding into clickable area and non-clickable area with margin
        margin-top: $sassy-spacing-x-small;
    }
}
