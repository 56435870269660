$user-type: 'buyer';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_spacing';

$device-type: 'responsive';

.button {
    --noop-rule: 0;
    @if $device-type == 'mobile' {
        &:hover:not(:disabled) {
            background: $sassy-color-white;
            border-color: $sassy-color-noir;
            color: $sassy-color-noir;
        }
    }
}

.heartLink {
    height: 33px;
    width: 33px;
    -webkit-tap-highlight-color: transparent;
}

.heartIcon {
    transition: fill 0.2s ease;
}
